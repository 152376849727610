import React from "react";
import PropTypes from "prop-types";
import ContactForm from "../../Contact/ContactForm";
import "./FormModal.scss";

const FormModal = (props) => {
  const fileDownload = () => {
    const link = document.createElement("a");
    link.href = props.fileUrl;
    link.download = props.fileName;
    link.click();
  };

  return (
    <div className="form-modal d-flex justify-content-center align-items-center">
      <div
        className="form-modal-overflow"
        onClick={props.handleOverflowClick}
      />
      <div className="form-modal-modal d-flex flex-column justify-content-center align-items-center">
        <div className="form-modal-modal-title">{props.title}</div>
        <div className="form-modal-modal-form">
          <ContactForm
            toEmail={props.toEmail}
            firstnameLabel={props.firstnameLabel}
            lastnameLabel={props.lastnameLabel}
            companyLabel={props.companyLabel}
            emailLabel={props.emailLabel}
            privacyLabel={props.privacyLabel}
            buttonLabel={props.buttonLabel}
            isDownload={true}
            onSuccess={() => fileDownload()}
          />
        </div>
        {/* <Link
          className="report-page-report-button form-modal-modal-button justify-content-center"
          to={props.link}
        >
          <span className="form-modal-modal-button-label">DOWNLOAD</span>
        </Link> */}
      </div>
    </div>
  );
};

FormModal.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  handleOverflowClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  toEmail: PropTypes.string.isRequired,
  firstnameLabel: PropTypes.string.isRequired,
  lastnameLabel: PropTypes.string.isRequired,
  companyLabel: PropTypes.string.isRequired,
  emailLabel: PropTypes.string.isRequired,
  privacyLabel: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default FormModal;
