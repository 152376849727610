import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulReportPage {
        nodes {
          node_locale
          title
          subtitle
          mainImage {
            gatsbyImageData
            title
          }
          description {
            description
          }
          reportImage {
            gatsbyImageData
            title
          }
          reportFile {
            file {
              url
              fileName
            }
          }
        }
      }
      allContentfulReportForm {
        nodes {
          node_locale
          title
          toEmail
          firstnameLabel
          lastnameLabel
          companyLabel
          emailLabel
          privacyLabel
          buttonLabel
        }
      }
    }
  `);
};

export default query;
