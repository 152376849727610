import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import query from "./query";
import PropTypes from "prop-types";
import { filterDataByLanguage } from "../../Utils/Language";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import FormModal from "./FormModal/FormModal";
import ReportHeader from "./ReportHeader/ReportHeader";
import arrowDownIcon from "../../assets/images/hevelop/arrow-down.svg";
import pointerDownIcon from "../../assets/images/hevelop/pointer-down.png";
import "./Report.scss";

const Report = (props) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const data = query();
  const reportPage = filterDataByLanguage(
    props.lang,
    data.allContentfulReportPage
  );
  const reportForm = filterDataByLanguage(
    props.lang,
    data.allContentfulReportForm
  );

  return (
    <div className="report-page">
      <Row className="d-none d-lg-block">
        <Col className="report-page-top-bar col-10 col-lg-8" />
      </Row>
      <ReportHeader
        title={reportPage.title}
        subtitle={reportPage.subtitle}
        mainImage={reportPage.mainImage}
      />
      <Container fluid>
        <Row className="report-page-description">
          <Col className="report-page-description-text col-10 col-lg-8">
            {reportPage.description.description}
          </Col>
          <Col className="report-page-description-icon col-10 col-lg-4 d-flex align-items-center">
            <img src={pointerDownIcon} alt="pointer down icon" />
          </Col>
        </Row>
      </Container>
      <Row className="report-page-report justify-content-center">
        <Col className="report-page-report-column">
          <Container fluid className="d-flex flex-column align-items-center">
            <GatsbyImage
              className="report-page-report-image"
              image={getImage(reportPage.reportImage)}
              alt={reportPage.reportImage.title}
            />
            <div
              className="report-page-report-button"
              onClick={() => setIsFormModalOpen(true)}
            >
              <img
                className="report-page-report-button-icon"
                src={arrowDownIcon}
                alt="arrow down icon"
              />
              <span className="report-page-report-button-label">DOWNLOAD</span>
              <img
                className="report-page-report-button-icon"
                src={arrowDownIcon}
                alt="arrow down icon"
              />
            </div>
          </Container>
          {isFormModalOpen && (
            <FormModal
              fileUrl={reportPage.reportFile.file.url}
              fileName={reportPage.reportFile.file.fileName}
              title={reportForm.title}
              toEmail={reportForm.toEmail}
              firstnameLabel={reportForm.firstnameLabel}
              lastnameLabel={reportForm.lastnameLabel}
              companyLabel={reportForm.companyLabel}
              emailLabel={reportForm.emailLabel}
              privacyLabel={reportForm.privacyLabel}
              buttonLabel={reportForm.buttonLabel}
              handleOverflowClick={() => setIsFormModalOpen(false)}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

Report.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Report;
