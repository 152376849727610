import * as React from "react";
import Header from "../../Components/Header/Header";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import Report from "../../Components/Report/Report";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";

const ReportPage = () => {
  return (
    <>
      <Seo locale="it" page="report" />
      <Header isDarkTheme={false} lang="it" />
      <HamburgerMenu />
      <Report lang="it" />
      <Partnership lang="it" />
      <Footer lang="it" />
    </>
  );
};

export default ReportPage;
